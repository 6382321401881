$size-sm : 640px !default;
$size-md : 769px !default;
$size-lg : 1024px !default;
$size-xl : 1280px !default;

@mixin breakpoint($size) {
    @if $size==sm {
        @media screen and (min-width:$size-sm) {
            @content;
        }
    }

    @else {
        @if $size==md {
            @media screen and (min-width:$size-md) {
                @content;
            }
        }

        @else {
            @if $size==lg {
                @media screen and (min-width:$size-lg) {
                    @content;
                }
            }

            @else {
                @if $size==xl {
                    @media screen and (min-width:$size-xl) {
                        @content;
                    }
                }
            }
        }
    }
}


b {
    font-weight: 500;
}

.tot-title {
    font-family: "Signika", system-ui;
    font-weight: 500;
}

.tot-bg-hero-desktop {
    height: auto;
    @include breakpoint(md) {
        background-image: url(../img/hero.jpg);
        background-repeat: no-repeat;
        background-position: center;
        height: 832px;
    }
}

.tot-bg-hero-mobile {
    height: clamp(50vh, 60vh, 60vh);
    background-image: url(../img/hero-mb.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    @include breakpoint(md) {
        background: none;
    }
}

.tot-strike {
    position: relative;
    display: inline-block;
    &:after {
        content: '';
        width: 100%;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 0px;
        z-index: 0;
    }
    & > b {
        position: relative;
        z-index: 5;
        padding: 0 4px;
    }

    &--turquoise {
        &:after {
            background-color: var(--turquoise-green);
        }
    }
    &--canary {
        &:after {
            background-color: var(--canary);
        }
    }
    &--sworange {
        &:after {
            background-color: var(--sweet-orange);
        }
    }
    &--violet {
        &:after {
            background-color: var(--violet);
        }
    }
}


.tot-btn {
    display: block;
    appearance: none;
    border: 0;
    color: darken(#1E2935,60); //#594C00;
    background-color: #fff;
    border-radius: 25px;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.25s ease;
    position: relative;
    &:hover {
        background-color: darken(#fff, 10);
        box-shadow: 0 2px 12px rgba(#121212, 0.3);
    }
    &:active {
        transform: translateY(1px);
    }
    &.button--full{
        width: 100%;
        padding: 0.75rem 1rem;
        text-align: center;
    }
}

.input-wrapper {
    display: flex;
    border: 2px solid transparent;
    border-radius: 100rem;
    padding: 0.25rem;
    margin: 0 auto;
    background-color: var(--deep-blue);
    @include breakpoint(sm) {
        max-width: 568px;
    }
}

.input {
    appearance: none;
    width: 100%;
    padding: 0.5rem 1.2rem;
    border: none;
    border-radius: 25px;
    background-color: transparent;
    font-size: 1rem;
    color: white;
    &::placeholder {
        color: rgba(255,255,255,0.7);
    }
}

// .bg-tribes-everyone {
//     background-image: url(../img/tribes-for-everyone-mb.png);
//     background-repeat: no-repeat;
//     background-position: center bottom;
//     background-size: contain;
//     height: clamp(50vh, 80vh, 60vh);
//     @include breakpoint(sm) {
//         height: 700px;
//     }
//     @include breakpoint(md) {
//         background-image: url(../img/tribes-for-everyone.png);
//         background-position: center bottom;
//         height: clamp(568px, 80vh, 80vh);
//         max-height: 568px;
//     }
// }

.survey-section {
    height: clamp(420px, 70vh, 70vh);
    @include breakpoint(md) {
        height: clamp(768px, 60vh, 60vh);
    }
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 45%;
        background-repeat: no-repeat;
        background-position: top -10px center;
        background-size: cover;
        background-image: url(../img/survey-img-top-mb.png);
        @include breakpoint(xl) {
            top: inherit;
            bottom: inherit;
            width: 50%;
            height: 100%;
            background-image: url(../img/survey-img-left-dk.png);
            background-position: left -50px top 50px;
            background-size: contain;
        }
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25%;
        background-repeat: no-repeat;
        background-position: bottom -10px center;
        background-size: cover;
        background-image: url(../img/survey-img-bottom-mb.png);
        @include breakpoint(xl) {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: inherit;
            bottom: inherit;
            width: 50%;
            height: 100%;
            background-image: url(../img/survey-img-right-dk.png);
            background-repeat: no-repeat;
            background-position: right -100px top 50px;
            background-size: contain;
        }
    }

    &--en {
        &:after {
            background-image: url(../img/survey-img-top-mb-en.png);
            @include breakpoint(xl) {
                background-image: url(../img/survey-img-left-dk-en.png);
            }
        }
        &:before {
            background-image: url(../img/survey-img-bottom-mb-en.png);
            @include breakpoint(xl) {
                background-image: url(../img/survey-img-right-dk-en.png);
            }
        }
    }
}

.join-the-list {
    // height: clamp(520px, 60vh, 60vh);
    background-image: url(../img/join-list-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    @include breakpoint(md) {
        height: clamp(768px, 60vh, 60vh);
    }
}

.tripontrib-footer {
    height: clamp(475px, 40vh, 40vh);
    background-image: url(../img/tot-footer.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include breakpoint(md) {
        height: clamp(768px, 60vh, 60vh);
    }
}

.tot-flow>*+* {
    margin-block-start: var(--flow-space, 1em);
}

.tot-prose {
    :is(h1, h2, h3, h4, h5, blockquote) {
        --flow-space: 1.25em;
    }

    :is(h1, h2, h3, h4, h5)+* {
        --flow-space: 1em;
    }

    p {
        font-size: inherit; //var(--step-5);
        line-height: 1.4;
        font-weight: 400;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        // font-family: 'Inter Display';
        font-weight: 700;
        line-height: 1.1;
        // text-wrap: balance;
    }

    h1 {
        font-size: var(--step-5);
    }

    h2 {
        font-size: var(--step-4);
    }

    h3 {
        font-size: var(--step-3);
    }

    h4 {
        font-size: var(--step-2);
    }

    h5 {
        font-size: var(--step-1);
    }

    h1 {
        max-width: 20ch;
    }

    :is(h1, h2, h3, h4, h5)+figure {
        padding-top: 0;
    }

    a {
        @apply underline;
    }

    ul {
        list-style-type: disc;
        padding-left: 1em;
        li {
            font-family: inherit;
            margin-bottom: .5em;
            font-weight: normal;
        }
    }
    ol {
        list-style-type:decimal;
        padding-left: 1em;
        li {
            font-family: inherit;
            margin-bottom: .5em;
            font-weight: normal;
        }
    }
}


/* ESPINER PARA LOS LOADING, POR TAMAÑO PARA BOTÓN */
.spinner {
    color: transparent !important;
    position: relative;
}
.spinner {
    &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid var(--deep-blue);
        border-top-color: rgba(#1E2935,.5);
        animation: spinner 0.6s linear infinite;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}


// .tot-agree-terms {
//     .tot-btn {
//         opacity: .5;
//         pointer-events: none;
//         user-select: none;
//     }
//     &:has(input[type='checkbox']:checked) {
//         .tot-btn {
//             opacity: 1;
//             pointer-events: inherit;
//             user-select: all;
//         }
//     }
// }
